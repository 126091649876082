<template>
  <candidate-details-table
    :report_title="$t('report.performance_score')"
    :items="performance_attrs"
    :fields="fields"
    :icon="'mdi mdi-clock-fast'"
    :candidate_score="candidate.high_performance_score"
    :highScore="report.bestPerformanceScore"
  ></candidate-details-table>
</template>

<script>
import { orderBy } from 'lodash';
import { mapGetters } from 'vuex';
import { calculateTwoSideProgressBarValue } from '@/common/utils';
import CandidateDetailsTable from '@/components/CandidateDetailsTable';

export default {
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    CandidateDetailsTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name',
        },
        {
          key: 'progress',
          thClass: '',
        },
        {
          key: 'score',
          thClass: 'attribute__score',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('report', ['reportAvgValues', 'report']),
    performance_attrs() {
      if (!this.candidate.high_performance_attrs) return [];

      const values = this.candidate.high_performance_attrs.map((item) => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.score,
          this.reportAvgValues[item.attribute]
        );

        return {
          progress: progressBarWidth,
          name: item.name,
          score: item.score,
        };
      });

      return orderBy(values, ['name'], ['asc']);
    },
  },
};
</script>
