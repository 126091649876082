<template>
  <!-- Two sider progress bar slider -->
  <!--<candidate-self-report-details-table-score :report_title="$t('report.potential_score')"
                                              :talents-attr="talentsAttr"
                                              :attribute-name="'high_potential'"
                                              :current-lang="currentLang"
                                              :score="report.high_potential_score"
                                              :isBlurred="isBlurred"
                                              :items="potential_attrs"
                                              :fields="fields"
                                              :icon="'mdi mdi-finance'"
                                              :max="max"></candidate-self-report-details-table-score>-->

  <!-- One sider progress bar slider -->
  <candidate-self-report-details-table
    :report_title="$t('report.defines_growth_potential')"
    :talents-attr="talentsAttr"
    :attribute-name="'high_potential'"
    :current-lang="currentLang"
    :score="report.high_potential_score"
    :isBlurred="isBlurred"
    :variant="'green2'"
    :items="potential_attrs"
    :fields="fields"
    :icon="'mdi mdi-finance'"
    :max="max"
  ></candidate-self-report-details-table>
</template>

<script>
import { orderBy } from 'lodash';
import { SELEFTEST_VALUES_MIN_VALUE } from '@/common/constants';
//import { calculateTwoSideProgressBarValue } from "@/common/utils";
//import { TALENT_TWO_SIDE_SLIDER_BREAK_POINT } from "@/common/constants";
//import CandidateSelfReportDetailsTableScore from '../CandidateDetailsTables/CandidateSelfReportDetailsTableScore';
import CandidateSelfReportDetailsTable from '../CandidateDetailsTables/CandidateSelfReportDetailsTable';

export default {
  props: {
    fullName: {
      type: String,
      required: true,
      default: '',
    },
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en',
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    isBlurred: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    //CandidateSelfReportDetailsTableScore,
    CandidateSelfReportDetailsTable,
  },
  data: function () {
    return {
      max: 100,
      fields: [
        {
          key: 'name',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name',
        },
        {
          key: 'value',
          label: this.$t('dashboard.progress'),
          thClass: '',
        },
      ],
    };
  },
  computed: {
    /*potential_attrs() {
      if (!this.report || !this.report.high_potential) return [];

      const values = this.report.high_potential.map(item => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.value,
          TALENT_TWO_SIDE_SLIDER_BREAK_POINT
        );

        return {
          key: item.key,
          value: progressBarWidth
        };
      });

      return orderBy(values, ['value'], ['desc'])
    }*/

    potential_attrs() {
      if (!this.report || !this.report.high_potential) return [];

      let values = this.report.high_potential.map((item) => {
        return {
          key: item.key,
          value: item.value,
        };
      });
      values = values
        .filter((item) => item.value >= SELEFTEST_VALUES_MIN_VALUE)
        .slice(0, 3);

      return orderBy(values, ['value'], ['desc']);
    },
  },
};
</script>
