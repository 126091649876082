<template>
  <div class="xmz-ap-campaign-table">
    <div v-if="showSpinner" class="spinner lg">
      <BSpinner />
    </div>
    <div v-else>
      <div class="d-flex flex-wrap mb-5">
        <div class="search-input-wrp mr-3">
          <label></label>
          <b-input-group>
            <b-form-select
              class="dashboard-table-top-select"
              :options="searchFields"
              v-model="searchField"
              @change="onSearchFieldsChange"
            ></b-form-select>
            <b-input-group-append v-if="searchField === 'status'">
              <b-form-select
                class="dashboard-table-top-search-select"
                :options="statusFields"
                v-model="searchSelect"
                @change="onStatusTypeChange"
              ></b-form-select>
            </b-input-group-append>

            <b-input-group-append v-if="searchField !== 'status'"
              ><b-form-input
                class="dashboard-table-top-search"
                :placeholder="$t('dashboard.search_placeholder')"
                v-on:keyup.enter="doSearch"
                v-model="searchQuery"
              ></b-form-input
            ></b-input-group-append>
            <b-input-group-append
              v-if="searchField !== 'status'"
              class="clear__search"
              ><b-btn @click="clearSearch"><i class="mdi mdi-close"></i></b-btn
            ></b-input-group-append>

            <b-input-group-append
              ><b-btn
                variant="primary"
                :disabled="!searchQuery || !searchField"
                @click="doSearch"
                ><search-icon></search-icon></b-btn
            ></b-input-group-append>
          </b-input-group>
        </div>

        <div class="search-input-wrp mr-3">
          <label>{{ $t('applicant_portal.education') }}</label>
          <multiselect
            label="text"
            track-by="value"
            v-model="education_keywords"
            :options="current.ap_data.education_keywords"
            :placeholder="$t('general.select_option')"
            :searchable="true"
            @input="onEducationKeywordsChange"
          />
        </div>

        <div class="search-input-wrp mr-3">
          <label>{{ $t('applicant_portal.qualification') }}</label>
          <multiselect
            label="text"
            track-by="value"
            v-model="job_keywords"
            :options="current.ap_data.job_keywords"
            :placeholder="$t('general.select_option')"
            :searchable="true"
            @input="onJobKeywordsChange"
          />
        </div>

        <div class="search-input-wrp mr-3">
          <label>{{ $t('applicant_portal.certificates') }}</label>
          <multiselect
            label="text"
            track-by="value"
            v-model="certificates"
            :options="current.ap_data.certificates"
            :placeholder="$t('general.select_option')"
            :searchable="true"
            @input="onCertificatesChange"
          />
        </div>

        <div class="search-input-wrp mr-3">
          <label>{{ $t('applicant_portal.top_candidates') }}</label>
          <app-select-input
            v-model="topCandidates"
            :options="topCandidatesFields"
            @input="onTopCandidatesChange"
          ></app-select-input>
        </div>
      </div>

      <div class="table-actions">
        <p>
          {{
            $t('report.ap_report_max_candidates_msg', {
              num: reportMaxCandidates,
            })
          }}
        </p>
        <div>
          <input
            type="file"
            ref="fileInput"
            :key="videoinputKey"
            @change="handleFileSelection"
            style="display: none"
          />

          <b-button
            v-if="!isPreview"
            @click="triggerFileSelection"
            variant="outline-primary"
          >
            Update Video URL's
          </b-button>
          <b-button
            v-if="!isPreview"
            @click="exportCandidates(true)"
            variant="outline-primary"
            >Export All</b-button
          >
          <b-button @click="exportCandidates(false)" variant="outline-primary"
            >Export Selected</b-button
          >
          <p v-if="showExportError" class="export-error-message">
            Please select atleast 1 candidate
          </p>
        </div>
      </div>
      <b-form-checkbox
        v-if="!isPreview"
        class="mr-3 report__anonymizer__checkbox"
        v-model="anonymizeCandidates"
        @input="anonymize"
        >{{ $t('report.anonymize_candidates') }}
        <span
          v-b-tooltip.hover
          :title="$t('report.anonymize_candidates_tooltip')"
          class="mdi mdi-information-outline"
        ></span>
      </b-form-checkbox>
      <br />
      <b-table
        hover
        responsive
        :items="items"
        :fields="fields"
        :no-local-sorting="true"
        :sort-by="sort.sortBy"
        :sort-desc="sort.sortDesc"
        @row-clicked="onRowClick"
        @sort-changed="sortingChanged"
      >
        <template v-slot:cell(select)="data">
          <b-checkbox
            v-model="data.item.selected"
            @change="
              onCandidateSelect(
                $event,
                data.item.user_id,
                (pagination.current_page - 1) * 20 + data.index
              )
            "
          ></b-checkbox>
        </template>
        <template v-slot:cell(no)="data">
          <div>{{ (pagination.current_page - 1) * 20 + data.index + 1 }}.</div>
        </template>
        <template v-slot:cell(title)="data">
          <div :class="`status-${data.item.status}`">
            {{ data.item.title }}
          </div>
        </template>
        <template v-slot:cell(first_name)="data">
          <div
            :class="
              checkAvailable(data.item.status, data.item.match_score).class
            "
          >
            {{ data.item.first_name }}
          </div>
        </template>
        <template v-slot:cell(last_name)="data">
          <div
            :class="
              checkAvailable(data.item.status, data.item.match_score).class
            "
          >
            {{ data.item.last_name }}
          </div>
        </template>
        <template v-slot:cell(email)="data">
          <div>{{ data.value[1] }}</div>
        </template>
        <template v-slot:cell(email_1)="data">
          <a :href="'mailto:' + data.item.email_1"
            ><img :src="emailPicture" width="20"
          /></a>
        </template>
        <template v-slot:cell(date)="data">
          <DateInterview :candidate="data.item" />
        </template>
        <template v-slot:cell(comment)="data">
          <CampaignCommentModal
            v-model="data.item.comment"
            @change="(comment) => updateCandidateComment(data.item, comment)"
          />
        </template>
        <template v-slot:cell(status)="data">
          <b-form-select
            class="participants-table-select"
            :options="statusFields"
            v-model="data.item.status"
            @change="(status) => updateCandidateStatus(data.item, status)"
          ></b-form-select>
        </template>
        <template v-slot:cell(references_requested_formated)="data">
          <div
            :class="
              checkAvailable(data.item.status, data.item.match_score).class
            "
          >
            {{ data.item.references_passed }} {{ referencesRequestedText }}
          </div>
        </template>
        <template v-slot:cell(match_score)="data">
          <div :class="`status-${data.item.status}`">
            {{ data.item.match_score }}
          </div>
        </template>
        <template v-slot:cell(professional_score)="data">
          <div :class="`status-${data.item.status}`">
            {{ data.item.professional_score }}
          </div>
        </template>
        <template v-slot:cell(personality_score)="data">
          <div v-for="score in bestMatchStatuses" :key="score.user_id">
            <div
              v-if="
                data.item.personality_score >= score.min &&
                data.item.personality_score <= score.max
              "
              :class="`status-${data.item.status}`"
            >
              {{ score.status }}
            </div>
          </div>
        </template>
        <template v-slot:cell(applied)="data">
          <div
            v-if="data.item.created_at"
            :class="`status-${data.item.status}`"
          >
            {{ $moment(data.item.created_at).format('DD MMM YYYY') }}
          </div>
        </template>

        <template v-slot:row-details="row">
          <div
            v-if="!campaignCandidatesAPData[row.item.id]"
            class="spinner small"
          >
            <BSpinner />
          </div>
          <div v-else>
            <CandidateDetails
              :item="row.item"
              :availableLanguages="availableLanguages"
              :anonymous="anonymous"
            />
          </div>
        </template>
      </b-table>
      <div class="recruiters-table">
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total_count"
          :per-page="pagination.per_page"
          :number-of-pages="pagination.total_pages"
          @change="onPageChange"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/Spinner';
import DateInterview from '@/components/DateInterview';
import CampaignCommentModal from '@/components/CampaignCommentModal';
import { BSpinner } from 'bootstrap-vue';
import CandidateDetails from './APCandidateDetails';
import {
  AP_REPORT_BULK_HIRING_MAX_CANDIDATES,
  AP_REPORT_MAX_CANDIDATES,
  BEST_MATCH_STATUSES,
  TOP_CANDIDATES
} from '../../../common/constants';
import ApCampaignService from '@/services/ap-campaign.service';
import EmailPicture from '../../../../images/emailPicture.svg';
import SearchIcon from '../../../icons/searchIcon';
import Multiselect from '@/components/CustomMultiselect';
import franchise from '../../../conf/franchise';
import AppSelectInput from '@/components/SelectInput';

export default {
  components: {
    Spinner,
    BSpinner,
    CandidateDetails,
    DateInterview,
    CampaignCommentModal,
    SearchIcon,
    Multiselect,
    AppSelectInput,
  },
  props: {
    items: {
      type: Array,
      require: true,
      default: () => [],
    },
    paginationDict: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: Number,
      require: true,
    },
    campaignId: {
      type: String,
      require: true,
    },
    sort: {
      type: Object,
      require: true,
    },
    campaignCandidatesAPData: {
      type: Object,
      default: () => {},
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    referencesRequested: {
      type: Number,
      default: 3,
    },
    availableLanguages: {
      type: Array,
      default: () => [],
    },
    isBulkHiring: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFile: null,
      videoinputKey: 0,
      itemsLoaded: false,
      allOpenRows: [],
      reportMaxCandidates: 5,
      pagination: {
        current_page: 1,
      },
      apCampaignService: null,
      showExportError: false,
      searchField: 'id',
      searchQuery: '',
      education_keywords: [],
      job_keywords: [],
      certificates: [],
      topCandidates: TOP_CANDIDATES.ALL,
      searchSelect: '',
      fields: [
        {
          key: 'select',
          label: '',
        },
        {
          key: 'no',
          label: '#',
        },
        {
          key: 'title',
          label: this.$t('general.title'),
        },
        {
          key: 'first_name',
          label: this.$t('general.first_name'),
        },
        {
          key: 'last_name',
          label: this.$t('general.last_name'),
        },
        {
          key: 'email_1',
          label: this.$t('general.primary_email'),
          class: 'text-center',
        },
        {
          key: 'date',
          label: this.$t('dashboard.date_interview'),
          class: 'text-center',
        },
        {
          key: 'comment',
          label: this.$t('dashboard.comment'),
          class: 'text-center',
        },
        {
          key: 'status',
          label: this.$t('dashboard.available'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'references_requested_formated',
          label: this.$t('dashboard.received_responses'),
          class: 'text-center',
        },
        {
          key: 'match_score',
          label: this.$t('applicant_portal.match_score'),
          sortable: true,
          class: 'text-center font-weight-bold',
        },
        {
          key: 'professional_score',
          label: this.$t('applicant_portal.professional_score'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'personality_score',
          label: this.$t('applicant_portal.personality_score'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'applied',
          label: this.$t('general.applied'),
          class: 'text-center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('campaign', ['anonymous', 'current']),
    emailPicture() {
      return EmailPicture;
    },
    referencesRequestedText() {
      return `${this.$t('general.of')} ${this.referencesRequested}`;
    },
    bestMatchStatuses() {
      return BEST_MATCH_STATUSES;
    },
    anonymizeCandidates: {
      get() {
        return this.anonymous;
      },
      set(value) {
        this.$store.commit('campaign/ANONYMIZE_CANDIDATES', value);
      },
    },
    searchFields() {
      return [
        {
          value: 'id',
          text: this.$t('general.id'),
        },
        {
          value: 'email',
          text: this.$t('general.email'),
        },
        {
          value: 'name',
          text: this.$t('general.name'),
        },
        {
          value: 'status',
          text: this.$t('dashboard.status'),
        },
      ];
    },
    topCandidatesFields() {
      return [
        {
          value: TOP_CANDIDATES.ALL,
          text: this.$t('applicant_portal.top_fields.all'),
        },
        {
          value: TOP_CANDIDATES.TOP_5,
          text: this.$t('applicant_portal.top_fields.top_5'),
        },
        {
          value: TOP_CANDIDATES.TOP_10,
          text: this.$t('applicant_portal.top_fields.top_10'),
        },
        {
          value: TOP_CANDIDATES.TOP_15,
          text: this.$t('applicant_portal.top_fields.top_15'),
        },
        {
          value: TOP_CANDIDATES.TOP_20,
          text: this.$t('applicant_portal.top_fields.top_20'),
        },
      ];
    },
    statusFields() {
      return [
        {
          value: 'available',
          text: this.$t('applicant_portal.available_fields.available'),
          isScoreIncludes: true,
        },
        {
          value: 'shortlisted',
          text: this.$t('applicant_portal.available_fields.shortlisted'),
          class: 'status-interview',
        },
        {
          value: 'interview_1',
          text: this.$t('applicant_portal.available_fields.interview_1'),
          class: 'status-interview',
        },
        {
          value: 'interview_2',
          text: this.$t('applicant_portal.available_fields.interview_2'),
          class: 'status-interview',
        },
        {
          value: 'interview_3',
          text: this.$t('applicant_portal.available_fields.interview_3'),
          class: 'status-interview',
        },
        {
          value: 'hired',
          text: this.$t('applicant_portal.available_fields.hired'),
          class: 'status-hired',
        },
        {
          value: 'not_available',
          text: this.$t('applicant_portal.available_fields.not_available'),
          class: 'status-not-available',
        },
        {
          value: 'not_selected',
          text: this.$t('applicant_portal.available_fields.not_selected'),
          class: 'status-not-available',
        },
      ];
    },
  },
  watch: {
    items(newVal) {
      if (!this.itemsLoaded && newVal && newVal.length) {
        this.setSelectedCandidates();
        this.itemsLoaded = true;
      }
    },
    paginationDict(newVal) {
      this.pagination = newVal;
    },
  },
  mounted() {
    this.apCampaignService = new ApCampaignService(this.campaignId);
    this.reportMaxCandidates = this.isBulkHiring
      ? AP_REPORT_BULK_HIRING_MAX_CANDIDATES
      : AP_REPORT_MAX_CANDIDATES;

    this.populateSearchFields();
  },
  methods: {
    onStatusTypeChange(event) {
      this.searchQuery = event;
    },
    triggerFileSelection() {
      this.$refs.fileInput.click();
    },
    handleFileSelection(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.$store
          .dispatch('campaign/uploadVideoUrls', {
            companyId: this.companyId,
            campaignId: this.campaignId,
            file: selectedFile,
          })
          .then(() => (this.videoinputKey += 1))
          .catch(() => (this.videoinputKey += 1));
      }
    },
    populateSearchFields() {
      console.log('filter', this.$route.query.filter);
      console.log('filter', this.$route.query.field);

      if (this.$route.query.filter) {
        this.searchField = this.$route.query.field;
      }

      if (this.$route.query.field) {
        this.searchQuery = this.$route.query.filter;
      }

      if (this.$route.query.job_keywords) {
        this.job_keywords = []
          .concat(this.$route.query.job_keywords)
          .map((i) => ({ value: i, text: i }));
      }

      if (this.$route.query.education_keywords) {
        this.education_keywords = []
          .concat(this.$route.query.education_keywords)
          .map((i) => ({ value: i, text: i }));
      }

      if (this.$route.query.certificates) {
        this.certificates = []
          .concat(this.$route.query.certificates)
          .map((i) => ({ value: i, text: i }));
      }

      if (this.$route.query.top) {
        this.topCandidates = this.$route.query.top;
      }

      console.log(this.$route.query);
    },
    anonymize() {
      this.$emit('anonymize');
    },
    setSelectedCandidates() {
      let selectedCndidates = this.apCampaignService.getCandidatesIds();
      let candidates = [];
      if (!selectedCndidates) {
        selectedCndidates = [];
        candidates = this.items.slice(0, this.reportMaxCandidates);
      } else {
        candidates = this.items.filter(
          (item) => selectedCndidates.indexOf(item.user_id) > -1
        );
      }

      candidates.forEach((candidate, index) => {
        this.apCampaignService.addCandidate({
          position: index,
          id: candidate.user_id,
        });
        candidate.selected = true;
      });
    },
    onCandidateSelect(event, id, position) {
      if (event) {
        this.apCampaignService.addCandidate({
          position: position,
          id: id,
        });
      } else {
        this.apCampaignService.removeCandidate({
          position: position,
          id: id,
        });
      }
    },
    updateCandidateComment(item, comment) {
      this.$store.dispatch('campaign/updateCandidateAP', {
        candidateId: item.id,
        comment,
      });
    },
    updateCandidateStatus(item, status) {
      this.$store.dispatch('campaign/updateCandidateAP', {
        candidateId: item.id,
        status,
      });
    },
    checkAvailable(status, score) {
      const statusField =
        this.statusFields.find((item) => item.value === status) || {};
      if (statusField.isScoreIncludes) {
        statusField.class = score ? 'status-score' : 'status-default';
      }
      return statusField;
    },
    onPageChange(page) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page,
        },
      });
    },
    onRowClick(item, index) {
      this.allOpenRows = [];
      this.$set(item, '_showDetails', !item._showDetails);
      this.allOpenRows.push(item);

      if (item._showDetails) {
        this.$emit('showDetails', item.id);
        this.$store.dispatch(
          this.isPreview
            ? 'campaign/fetchPreviewCandidateAPData'
            : 'campaign/fetchCandidateAPData',
          {
            companyId: this.companyId,
            campaignId: this.campaignId,
            candidateId: item.id,
            token: this.$route.query._token,
          }
        );
      }
    },
    sortingChanged(event) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortBy: event.sortBy,
          sortDesc: event.sortDesc,
        },
      });

      this.apCampaignService.clear();
    },
    exportCandidates(all = false) {
      this.showExportError = false;

      let ids = null;
      if (!all) {
        ids = this.apCampaignService.getCandidatesIds();
        if (!ids || !ids.length) {
          this.showExportError = true;
          return;
        }
      }

      if (this.isPreview) {
        window.open(
          `${franchise.apiUrl}/api/v1/campaigns/preview_export` +
            `?sort_by=${this.sort.sortBy}&sort_desc=${
              this.sort.sortDesc
            }&lang=${localStorage.getItem('language')}&_token=${
              this.$route.query._token
            }` +
            `${ids ? '&ids=' + ids : ''}`,
          '_blank'
        );
      } else {
        window.open(
          `${franchise.apiUrl}/api/v1/companies/${this.companyId}/campaigns/${this.campaignId}` +
            `/candidates/export?anonymous_mode=${
              this.anonymizeCandidates
            }&sort_by=${this.sort.sortBy}&sort_desc=${
              this.sort.sortDesc
            }&lang=${localStorage.getItem(
              'language'
            )}&_token=${window.localStorage.getItem('user-token')}` +
            `${ids ? '&ids=' + ids : ''}`,
          '_blank'
        );
      }
    },
    doSearch() {
      if (!this.searchQuery || !this.searchField) return;

      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          filter: this.searchQuery,
          field: this.searchField,
        },
      });
    },
    clearSearch() {
      this.searchQuery = '';
      this.searchField = 'id';
      this.$router.push({
        path: this.$route.path,
        query: {},
      });
    },
    onSearchFieldsChange() {
      this.searchQuery = '';
    },
    onEducationKeywordsChange(educationKeywords) {
      this.setKeywords(
        'education_keywords',
        educationKeywords.map((i) => i.value)
      );
    },
    onJobKeywordsChange(jobKeywords) {
      this.setKeywords(
        'job_keywords',
        jobKeywords.map((i) => i.value)
      );
    },
    onCertificatesChange(certificates) {
      this.setKeywords(
        'certificates',
        certificates.map((i) => i.value)
      );
    },
    setKeywords(key, values) {
      const filter = {};
      filter[key] = values;
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...filter,
        },
      });
    },
    onTopCandidatesChange(top) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sortBy: 'match_score',
          sortDesc: true,
          top,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.xmz-ap-campaign-table {
  tbody {
    cursor: pointer !important;
  }

  .recruiters-table {
    float: right !important;
  }

  .spinner {
    color: var(--primary);
    text-align: center;

    span {
      position: relative;
    }

    &.small {
      padding-bottom: 20px;
      font-size: 2rem;
    }

    &.lg {
      padding-top: 40%;
      span {
        height: 5rem;
        width: 5rem;
        font-size: 3rem;
      }
    }
  }

  .status {
    &-score {
      color: black;
    }

    &-default {
      color: gray;
    }

    &-interview {
      color: blue;
    }

    &-hired {
      color: green;
    }

    &-not-available {
      color: gray;
      text-decoration: line-through;
    }
  }

  .table-actions {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 5px;
    }

    .export-error-message {
      padding: 10px 10px 0px 10px;
      color: #e64646;
    }
  }
  .b-table-has-details {
    font-weight: bold;
  }

  #export-alert-toast {
    margin-top: 10px;
  }
}
</style>