<template>
  <div class="interview__questions__content">
    <b-row>
      <b-col md="12" lg="12">
        <div class="toggle">
          <h2 class="report__section__title toggle__trigger">
            <span class="toggle__trigger__text">{{
              $t('report.possible_interview_questions')
            }}</span>
          </h2>
        </div>
      </b-col>
    </b-row>

    <div
      class="interview__question__item"
      v-for="(item, index) in interviewQuestions"
      :key="index"
    >
      <b-row>
        <b-col md="6" lg="6" class="col-print-6 pr-4">
          <h3 class="interview__question__topic">{{ item.title }}</h3>
          <p class="interview__question__title">{{ item.text[0] }}</p>
          <ul class="interview__question__list">
            <li
              v-for="(item, index) in item.text.slice(1, item.text.lenght)"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </b-col>

        <b-col md="6" lg="6" class="col-print-6 pl-4">
          <div class="interview__question__notes">
            <div class="interview__question__note">
              <table class="table report__ems__table has__lines">
                <thead>
                  <tr>
                    <th>{{ $t('report.notes') }}</th>
                    <th class="text-right">
                      <div class="interview__question__score">
                        <span>{{ $t('report.score') }}</span
                        >{{ $t('general.of') }} 10
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2"><span>{{ $t('report.note') }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"><span>{{ $t('report.note') }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"><span>{{ $t('report.note') }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col>
        <div class="interview__question__bottomnote">
          <div class="interview__question__notes">
            <div class="interview__question__note">
              <table class="table report__ems__table has__lines">
                <thead>
                  <tr>
                    <th>{{ $t('report.notes') }}</th>
                    <th class="text-right">
                      <div class="interview__question__score">
                        <span>{{ $t('report.overal_score') }}</span
                        >{{ $t('general.of') }} 10
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2"><span>{{ $t('report.note') }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"><span>{{ $t('report.note') }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="2"><span>{{ $t('report.note') }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    interviewQuestions: {
      type: Array,
      default: () => []
    }
  }
};
</script>
