import campaign from '../../../api/campaign';
import ApiCampaign from '../../../api/campaign';

const populateTranslations = (campaign, attributes, attempt = 1) => {
  try {
    ['values', 'competencies', 'traits'].forEach(key => {
      if (!campaign.personality_attrs || !campaign.personality_attrs[key]) {
        return;
      }
      campaign.personality_attrs[key].forEach(attr => {
        if (!attributes[key][attr.attribute]) {
          return
        }
        const keys = Object.keys(attributes[key][attr.attribute])
        keys.forEach(k => {
          if (/name_\w/.test(k)) {
            attr[k] = attributes[key][attr.attribute][k];
          }
        })
      })
    })
  } catch (err) {
    if (attempt < 1000) {
      populateTranslations(campaign, attributes, attempt + 1)
    }
  }
}

const fetchCampaigns = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.fetchCampaigns(companyId, params).then((response) => {
    const attributes = rootGetters['global/campaignAttributes'];
    response.data.campaigns.forEach(campaign => {
      populateTranslations(campaign, attributes)
    });
    commit('SET_CAMPAIGN_LIST', response.data || []);
  });
};

const getCampaign = ({ commit, rootGetters }, id) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.getCampaign(companyId, id).then((response) => {
    const attributes = rootGetters['global/campaignAttributes'];
    const campaign = response.data.campaign;
    populateTranslations(campaign, attributes)
    commit('SET_CAMPAIGN', campaign);
  }).catch(e => window.location.href = '/');
};

const getCampaignPreview = ({ commit, rootGetters }, token) => {
  return ApiCampaign.getCampaignPreview(token).then((response) => {
    const attributes = rootGetters['global/campaignAttributes'];
    const campaign = response.data.campaign;
    populateTranslations(campaign, attributes)
    commit('SET_CAMPAIGN', campaign);
  })
  // .catch(e => window.location.href = '/');
};


const populateCampaignTranslations = ({ commit, rootGetters }, campaign) => {
  const attributes = rootGetters['global/campaignAttributes'];
  populateTranslations(campaign, attributes)
  commit('SET_CAMPAIGN', campaign);
}

const resetCampaign = ({ commit }) => {
  commit('RESET_CAMPAIGN');
};

const createCampaign = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.createCampaign(companyId, params).then((response) => {
    const attributes = rootGetters['global/campaignAttributes'];
    const campaign = response.data.campaign;
    populateTranslations(campaign, attributes)
    commit('UPDATE_STATE_CAMPAIGN', campaign);
  })
};

const updateCampaign = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.updateCampaign(companyId, params.id, params).then((response) => {
    const attributes = rootGetters['global/campaignAttributes'];
    const campaign = response.data.campaign;
    populateTranslations(campaign, attributes)
    commit('UPDATE_CAMPAIGN', campaign);
  });
};

const updateCampaignComment = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.updateCampaignComment(companyId, params.id, params).then((response) => {
    const attributes = rootGetters['global/campaignAttributes'];
    const campaign = response.data.campaign;
    populateTranslations(campaign, attributes)
    commit('UPDATE_CAMPAIGN', campaign);
  });
};

const generatePreviewToken = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.generatePreviewToken(companyId, params.id, params.linkExpirationPeriod)
};

const updateStateCampaign = ({ commit }, params) => {
  commit('UPDATE_STATE_CAMPAIGN', params);
};

const useTemplate = ({ commit, rootGetters }, params) => {
  commit('USE_TEMPLATE', params);

  setTimeout(() => {
    commit('UPDATE_AP_REFRESH_KEY');
  }, 1)

  const campaign = rootGetters['campaign/current'];
  const attributes = rootGetters['global/campaignAttributes'];
  populateTranslations(campaign, attributes)
};

const updateState = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.updateBulkState(companyId, params).then((response) => {
    commit('SET_CAMPAIGN_STATE', params.newState)
  })
}

const fetchCandidates = ({ commit, rootGetters }, params) => {
  return ApiCampaign.fetchCandidates(params.companyId, params.campaignId, params.page, params.sortBy, params.sortDesc, rootGetters['campaign/anonymous'], params.field, params.filter, params.education_keywords, params.job_keywords, params.certificates).then(res => {
    commit('SET_CAMPAIGN_CANDIDATES', res.data.campaign_candidates);
    commit('SET_CAMPAIGN_CANDIDATES_PAGINATION', res.data.meta);
    commit('SET_TOP_CAMPAIGN_CANDIDATES', params.top);
  })
}

const fetchPreviewCandidates = ({ commit }, params) => {
  return ApiCampaign.fetchPreviewCandidates(params.token, params.page, params.sortBy, params.sortDesc, params.education_keywords, params.job_keywords, params.certificates).then(res => {
    commit('SET_CAMPAIGN_CANDIDATES', res.data.campaign_candidates);
    commit('SET_CAMPAIGN_CANDIDATES_PAGINATION', res.data.meta);
  })
}

const updateCandidateAP = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  const campaign = rootGetters['campaign/current']
  return ApiCampaign.updateCandidateAP(companyId, campaign.id, params.candidateId, params)
}

const uploadCandidateDocuments = ({ commit, rootGetters }, params) => {
  const campaign = rootGetters['campaign/current']
  return ApiCampaign.uploadCandidateDocuments(
    campaign.id,
    params.candidateId,
    params)
}

const fetchCandidateAPData = ({ commit, rootGetters }, params) => {
  return ApiCampaign.fetchCandidateAPData(params.companyId, params.campaignId, params.candidateId, rootGetters['campaign/anonymous']).then(res => {
    const data = {};
    data[params.candidateId] = res.data.ap_data
    commit('SET_CANDIDATE_AP_DATA', data)
  })
}

const fetchPreviewCandidateAPData = ({ commit }, params) => {
  return ApiCampaign.fetchPreviewCandidateAPData(params.token, params.candidateId).then(res => {
    const data = {};
    data[params.candidateId] = res.data.ap_data
    commit('SET_CANDIDATE_AP_DATA', data)
  })
}

const recalculateReport = ({ commit }, params) => {
  return ApiCampaign.recalculateReport(params.companyId, params.campaignId, params.data);
}

const cloneCampaign = ({ commit, rootGetters }, params) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCampaign.cloneCampaign(companyId, params.id, params).then((response) => {
    commit('SET_CAMPAIGN', response.data.campaign);
  })
};

const uploadVideoUrls = ({ rootGetters }, payload) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  const formData = new FormData();
  formData.append('file', payload.file);
  return ApiCampaign.uploadVideoUrls(companyId, payload.companyId, formData);
}

export default {
  fetchCampaigns,
  getCampaign,
  getCampaignPreview,
  resetCampaign,
  createCampaign,
  updateCampaign,
  updateCampaignComment,
  generatePreviewToken,
  updateStateCampaign,
  useTemplate,
  updateState,
  populateCampaignTranslations,
  fetchCandidates,
  fetchPreviewCandidates,
  fetchCandidateAPData,
  fetchPreviewCandidateAPData,
  recalculateReport,
  cloneCampaign,
  updateCandidateAP,
  uploadVideoUrls,
  uploadCandidateDocuments
};
