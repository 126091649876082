<template>
  <div>
    <report-personality-type :personality-type="report.personality_type"></report-personality-type>
    <slot></slot>
    <report-performance :full-name="fullName" :report="report" :talents-attr="talentsAttr" :current-lang="currentLang" :is-blurred="isBlurred"></report-performance>
    <report-potential :full-name="fullName" :report="report" :talents-attr="talentsAttr" :current-lang="currentLang" :is-blurred="isBlurred"></report-potential>
    <!-- <report-rock-star :full-name="fullName" :report="report" :talents-attr="talentsAttr" :current-lang="currentLang" :is-blurred="isBlurred"></report-rock-star> -->
  </div>
</template>

<script>
import PersonalityType from "./PersonalityType";
import Performance from "./Performance";
import Potential from "./Potential";
// import RockStar from "./RockStar";

export default {
  components: {
    ReportPersonalityType: PersonalityType,
    ReportPerformance: Performance,
    ReportPotential: Potential,
    // ReportRockStar: RockStar
  },
  props: {
    fullName: {
      type: String,
      required: true,
      default: ""
    },
    report: {
      type: Object,
      required: true,
      default: () => {}
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {}
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en'
    },
    isRegistered: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    isBlurred() {
      return !this.isRegistered && !(localStorage.getItem('nb') === 'true');
    }
  }
};
</script>
