<template>
  <div class="report__chapter">

    <b-row>
      <b-col>
        <h3 class="report__chapter__title">
          <span class="mdi mdi-human"></span>
          <span>{{ $t("report.important_to_you") }}</span>
        </h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="6" lg="6" class="col-print-6" v-for="(item, index) in radialProgress.slice(0, 2)" :key="index">
        <div class="personality__progress__item">
          <report-radial-progress :value="item.score" :color="getColor(item.attribute)"></report-radial-progress>
          <span class="personality__progress__label">{{ getMessageByColor(item.attribute) }}</span>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { RADIAL_PROGRESS_MULTIPLY, PERSONALITY_TYPES_COLORS, PERSONALITY_TYPES_COLORS_TITLES } from "@/common/constants";
import RadialProgress from "@/components/RadialProgress";

export default {
  components: {
    ReportRadialProgress: RadialProgress
  },
  props: {
    personalityType: {
      type: Array,
      required: true,
      default: []
    },
    talentsAttr: {
      type: Object,
      default: () => {},
    },
    currentLang: {
      type: String,
      default: "en",
    },
  },
  data() {
    return {
      personalityColors: {
        score_g_1_1: "Red",
        score_g_1_2: "Green",
        score_g_1_3: "Blue",
        score_g_1_4: "Yellow",
      }
    }
  },
  computed: {
    radialProgress() {
      return (
        this.personalityType &&
        this.personalityType.map((item, index) => {
          return {
            color_key: item.key,
            score: this.multiplyRadialScore(index, item.value),
            attribute: item.key
          };
        })
      ) || [];
    },
  },
  methods: {
    multiplyRadialScore(index, value) {
      return index === 1 ? Math.round(value * RADIAL_PROGRESS_MULTIPLY) : value
    },
    getColor(attribute) {
      return PERSONALITY_TYPES_COLORS[attribute] || "transparent";
    },
    getMessageByColor(attribute) {
      return this.$t(
        PERSONALITY_TYPES_COLORS_TITLES[attribute] ||
          this.talentsAttr['values'][attribute][`name_${this.currentLang}`] ||
          ''
      );
    },
  }
};
</script>
