<template>
  <div class="row">
    <div class="col-6">
      <div class="mt-3" v-for="(item, index) in leftColumn" :key="index">
        {{ item.text }}
      </div>
    </div>
    <div class="col-6">
      <div class="mt-3" v-for="(item, index) in rightColumn" :key="index">
        {{ item.text }}
      </div>
    </div>

    <!-- <div class="personality__type__item" v-for="(item, index) in personality_types" :key="index">
      <b-row>
        <b-col md="3" lg="3" class="col-print-3">
          <div class="personality__type__icon" :class="item.color.toLowerCase()">
            <span :class="item.icon"></span>
          </div>
          <h4 class="personality__type__motto">{{ $t(item.motto) }}</h4>
        </b-col>

        <b-col md="9" lg="9" class="col-print-9">
          <h2>{{ $t(item.name) }} ({{ $t(item.type) }})</h2>
          <h3>{{ $t(item.subtitle) }}</h3>
          <p>{{ $t(item.text) }}</p>
        </b-col>
      </b-row>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    personality_types() {
      return [
        {
          icon: 'mdi mdi-human-handsup',
          color: 'red',
          name: this.$t('personality_type_content.red.name'),
          type: this.$t('personality_type_content.red.type'),
          subtitle: this.$t('personality_type_content.red.subtitle'),
          motto: this.$t('personality_type_content.red.motto'),
          text: this.$t('personality_type_content.red.text'),
        },
        {
          icon: 'mdi mdi-meditation',
          color: 'green',
          name: this.$t('personality_type_content.green.name'),
          type: this.$t('personality_type_content.green.type'),
          subtitle: this.$t('personality_type_content.green.subtitle'),
          motto: this.$t('personality_type_content.green.motto'),
          text: this.$t('personality_type_content.green.text'),
        },
        {
          icon: 'mdi mdi-human-male-height',
          color: 'blue',
          name: this.$t('personality_type_content.blue.name'),
          type: this.$t('personality_type_content.blue.type'),
          subtitle: this.$t('personality_type_content.blue.subtitle'),
          motto: this.$t('personality_type_content.blue.motto'),
          text: this.$t('personality_type_content.blue.text'),
        },
        {
          icon: 'mdi mdi-charity',
          color: 'yellow',
          name: this.$t('personality_type_content.yellow.name'),
          type: this.$t('personality_type_content.yellow.type'),
          subtitle: this.$t('personality_type_content.yellow.subtitle'),
          motto: this.$t('personality_type_content.yellow.motto'),
          text: this.$t('personality_type_content.yellow.text'),
        },
      ];
    },
    leftColumn() {
      return this.personality_types.slice(0, 2);
    },
    rightColumn() {
      return this.personality_types.slice(2, 4);
    },
  },
};
</script>
