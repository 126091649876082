<template>
  <!-- Two sider progress bar slider -->
  <!--<candidate-self-report-details-table-score :report_title="$t('js_report.values')"
                                       :talents-attr="talentsAttr"
                                       :attribute-name="'values'"
                                       :current-lang="currentLang"
                                       :score="-1"
                                       :items="candidate_values"
                                       :fields="fields"
                                       :icon="'mdi mdi-castle'"
                                       :max="max"></candidate-self-report-details-table-score>-->

  <!-- One sider progress bar slider -->
  <!-- <candidate-self-report-details-table
    :report_title="title"
    :talents-attr="talentsAttr"
    :attribute-name="'values'"
    :current-lang="currentLang"
    :score="-1"
    :items="candidate_values"
    :variant="'primary'"
    :fields="fields"
    :icon="'mdi mdi-castle'"
    :max="max"
    :is-shown="isShown"
  ></candidate-self-report-details-table> -->
  <personality-type
    :personality-type="candidate_values"
    :talents-attr="talentsAttr"
    :current-lang="currentLang"
  ></personality-type>
</template>

<script>
import { orderBy, isEmpty } from 'lodash';
import { SELEFTEST_VALUES_MIN_VALUE } from '@/common/constants';
//import CandidateSelfReportDetailsTableScore from './CandidateDetailsTables/CandidateSelfReportDetailsTableScore';
import CandidateSelfReportDetailsTable from './CandidateDetailsTables/CandidateSelfReportDetailsTable';
import PersonalityType from './Candidate/PersonalityType.vue';

export default {
  props: {
    candidateValues: {
      type: Array,
      required: true,
      default: () => {},
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en',
    },
  },
  components: {
    //CandidateSelfReportDetailsTableScore,
    CandidateSelfReportDetailsTable,
    PersonalityType,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name',
        },
        {
          key: 'value',
          label: this.$t('dashboard.progress'),
          thClass: '',
        },
      ],
      max: 100,
    };
  },
  computed: {
    //Two sided progress bar slider - calcaulation
    /*candidate_values() {
      if (!this.candidateValues) return [];

      const values = this.candidateValues.map(item => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.value,
          TALENT_TWO_SIDE_SLIDER_BREAK_POINT
        );

        return {
          key: item.key,
          value: progressBarWidth
        };
      });

      return orderBy(values, ['value'], ['desc'])
    }*/

    //One sided progress bar slider
    candidate_values() {
      if (!this.candidateValues) return [];

      let values = this.candidateValues.map((item) => {
        return {
          key: item.key,
          value: item.value,
        };
      });
      values = values
        .filter((item) => item.value >= SELEFTEST_VALUES_MIN_VALUE)
        .slice(0, 2);
      return orderBy(values, ['value'], ['desc']);
    },

    title() {
      return `${this.$t('report.your_top', {
        top: this.candidate_values.length,
      })} ${this.$t('js_report.values')}`;
    },
    isShown() {
      return !isEmpty(this.candidate_values);
    },
  },
};
</script>
