<template>
  <b-row>
    <b-col>
      <div class="report__chapter">
        <figure>
          <img :src="candidateCoverIllustration" alt="Campaign Report">
        </figure>

        <div class="report__section__grouptitle mt-5">
          <span>{{ $t("report.candidate_details") }}</span>
        </div>

        <h1 class="w-25">{{ $t("report.personality_for") }}</h1>

        <h1 class="mt-2">{{ fullName }}</h1>

        <div class="coverpage__attributes">
          <div class="coverpage__attribute__group">
            <div class="coverpage__attribute__label">{{ $t("js_report.assessment_date") }}</div>
            <div class="coverpage__attribute__value">{{ report.assessment_date }}</div>
          </div>

          <div class="coverpage__attribute__group">
            <div class="coverpage__attribute__label">{{ $t("report.job_id") }}</div>
            <div class="coverpage__attribute__value">{{ report.id }}</div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CandidateCoverIllustration from "../../../../images/report/print/report_candidate_new.svg";

export default {
  props: {
    report: {
      type: Object,
      required: false,
      default: () => {}
    },
    fullName: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    candidateCoverIllustration() {
      return CandidateCoverIllustration;
    }
  },
};
</script>

<style lang="css" scoped>
  body.print__report .report .page_campaign_cover h1, body.print__report .report .page_candidate_cover h1 {
      width: 50%;
  }

  body.print__report .report .page_campaign_cover figure, body.print__report .report .page_candidate_cover figure {
    top: 110mm;
  }
</style>
