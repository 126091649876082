import i18n from '@/i18n';
import franchiseConf from '../conf/franchise';

console.log('franchiseConf', franchiseConf)

const EXAMPLE_QUESTION_CLASS_NAME = 'example-question'

const BAR_CENTER_CAMPAIGN_REPORT = 50

const MATCH_TWO_SIDE_SLIDER_BREAK_POINT = 50
const TALENT_TWO_SIDE_SLIDER_BREAK_POINT = 50

const BAR_CENTER_SELF_REPORT = 50

const HIGH_PREFORMER_MIN_SCORE = 75

const HIGH_POTENTIAL_MIN_SCORE = 75

const FETCH_SELF_TEST_REPORT_INTERVAL = 10000

const TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING = 25

const MAX_PRINT_CANDIDATES_ALLOWED = 10

const BEST_MATCH_SCORE_SUCCESS = 80

const SELF_TEST_SCORE_SUCESS = 60

const MIN_QUALIFIED_SCORE = 55

const RADIAL_PROGRESS_MULTIPLY = .6

const SELEFTEST_VALUES_MIN_VALUE = 50

const PERSONALITY_TYPES_COLORS = {
  // Match rerpot
  'match_g_1_1': '#DE0632', // red
  'match_g_1_2': '#146D45', // green
  'match_g_1_3': '#086CD2', // blue
  'match_g_1_4': '#FFD01A', // yellow
  // Self test report
  'score_g_1_1': '#DE0632', // red
  'score_g_1_2': '#146D45', // green
  'score_g_1_3': '#086CD2', // blue
  'score_g_1_4': '#FFD01A', // yellow
  'score_a_1_1': '#DE0632', // red
  'score_a_1_2': '#146D45', // green
  'score_a_1_3': '#086CD2', // blue
  'score_a_1_4': '#FFD01A', // yellow
}

const PERSONALITY_TYPES_COLORS_TITLES = {
  // Match rerpot
  'match_g_1_1': 'report.personality_type_color_title.red', // red
  'match_g_1_2': 'report.personality_type_color_title.green', // green
  'match_g_1_3': 'report.personality_type_color_title.blue', // blue
  'match_g_1_4': 'report.personality_type_color_title.yellow', // yellow
  // Self test report
  'score_g_1_1': 'report.personality_type_color_title.red', // red
  'score_g_1_2': 'report.personality_type_color_title.green', // green
  'score_g_1_3': 'report.personality_type_color_title.blue', // blue
  'score_g_1_4': 'report.personality_type_color_title.yellow', // yellow
}

const CAMPAIGN_MIN_CANDIDATES = 1
const CAMPAIGN_MIN_TEAMS_CANDIDATES = 3

const CAMPAIGN_STATE = {
  OPEN: "open",
  ACTIVE: "active",
  FINALIZED: "finalized",
  CLOSED: "closed",
  CANCELED: "canceled",
  ARCHIVED: "archived",
}

const CAMPAIGN_TYPES = {
  JOB: "job",
  TEAM: "team",
  SELFTEST: "selftest",
  NO_ASSASMENT: 'no_assasment'
}

const REPORT_SCALE_MIN_VALUE = 11;
const REPORT_SCALE_MAX_VALUE = 25;
const REPORT_SCALE_MIN_SCORE = -90;
const REPORT_SCALE_MAX_SCORE = 20;
const REPORT_SCALE_THRESHOLD = 20;
const REPORT_SCALE_SCALED_RANGE = REPORT_SCALE_MAX_VALUE - REPORT_SCALE_MIN_VALUE;
const REPORT_SCALE_RANGE = REPORT_SCALE_MAX_SCORE - REPORT_SCALE_MIN_SCORE;

const NOT_REGISTED_USER_TYPES = [
  'Reference',
  'Candidate',
  'SelftestUser'
]

const USER_TYPES = [
  'Recruiter',
  'JobSeeker',
].concat(NOT_REGISTED_USER_TYPES)

const RECRUITER_TYPE = USER_TYPES[0]
const JOB_SEEKER_TYPE = USER_TYPES[1]

const CONSOLE_LOG_MSG = `We love smart and curious people – and you seem to be one.
So, if you are open to find awesome creative jobs: just send us a quick message with your name to together with 3 short answers (not longer than 200 characters each) genius@examiz.com:
(1) What is the one thing you did you are most proud of?
(2) If could do one thing in your life differently: what would that be?
(3) What is the one thing you wish to be better in?`

const PROMO_CODE_PREFIXES = {
  SELFTEST: 'st'
}

const ATTRIBUTES_KEYS_MATCH = {
  HIGH_PERFORMER_KEY: 'match_f_1_1',
  HIGH_POTENTIAL_KEY: 'match_f_1_2',
}

const ATTRIBUTES_KEYS_TALENT = {
  HIGH_PERFORMER_KEY: 'score_f_1_1',
  HIGH_POTENTIAL_KEY: 'score_f_1_2',
}

const JOB_COMPLEXITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  VERY_HIGH: 'very_high'
}

const CURRENCIES = [
  { isoCode: 'USD', symbol: '$' },
  { isoCode: 'EUR', symbol: '€' },
  { isoCode: 'CHF', symbol: 'Fr.' },
  { isoCode: 'GBP', symbol: '£' },
  { isoCode: 'AUD', symbol: 'A$' }
]

const JOB_RANGE_TYPES = {
  NO_RANGE: 'no_range',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  PROJECT: 'project'
}

const JOB_ARRANGEMENT_TYPES = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  TEMPORARY: 'temporary',
  PROJECT: 'project',
}

const JOB_ARRANGEMENT_PERIODS = [
  {
    value: 'lt3',
    text: `<3 ${i18n.t('general.months')}`,
  },
  {
    value: 'btw3-6',
    text: `3 - 6 ${i18n.t('general.months')}`
  },
  {
    value: 'btw6-12',
    text: `6 - 12 ${i18n.t('general.months')}`
  },
  {
    value: 'gt12',
    text: `>12 ${i18n.t('general.months')}`
  },
]
const BEST_MATCH_STATUSES = [
  { min: 90, max: 100, status: "A+", status_key: "excellent_fit" },
  { min: 80, max: 89, status: "A", status_key: "very_good_fit" },
  { min: 70, max: 79, status: "B", status_key: "good_fit" },
  { min: 50, max: 69, status: "C", status_key: "acceptable_fit" },
  { min: 30, max: 49, status: "D", status_key: "poor_fit" },
  { min: 0, max: 29, status: "-", status_key: "null_fit" },
];

const AP_REPORT_SLELECTED_CANDIDATES_LS_KEY = 'ap_portal_selected_candidates';
const AP_REPORT_MAX_CANDIDATES = 5;
const AP_REPORT_BULK_HIRING_MAX_CANDIDATES = 20;
const MATCH_SCORE_MIN = 22;
const EXIT_MAX_CHARACTERS_STRATEGY = 7000;
const EXIT_MAX_RISK = 20;

const JOB_LEVEL = {
  DIRECTOR: 'director',
  MANAGEMENT: 'management',
  TEAM_LEADER: 'team_leader',
  PROFESSIONAL: 'professional',
  JUNIOR: 'junior',
  ENTRY: 'entry'
};

const PERFORMANCE_LEVEL = {
  TOP_1: 'top_1',
  TOP_10: 'top_10',
  VERY_GOOD: 'very_good',
  GOOD: 'good',
  AVERAGE: 'average',
  BELOW_AVERAGE: 'below_average'
};

const EXIT_CAMPAIGN_TYPES = {
  SIMPLE: 'simple',
  ANALYTICS: 'analytics',
};

const EXIT_CAMPAIGN_STATE = {
  OPEN: "open",
  ACTIVE: "active",
  FINALIZED: "finalized",
};

const EXIT_CAMPAIGN_ROLE = {
  TEAM_LEAD: "team_lead",
  LEAVING_PERSON: "leaving_person",
};

const EXAMIZ_APPS = {
  DEFAULT: {
    type: 'default',
    url: '',
    title: franchiseConf.matchTitle,
    reportTitle: franchiseConf.matchReportTitle,
  },
  EXIT: {
    type: 'exit',
    url: 'exit',
    title: franchiseConf.pulseTitle,
    reportTitle: franchiseConf.pulseReportTitle,
  }
};

const TEAM_MEMBERS_ROLE = {
  LEAVING_PERSON: 'leaving_person',
  TEAM_LEADER: 'team_leader',
  LINE_MANAGER: 'line_manager',
  TEAM_MEMBER: 'team_member',
  CONSULTANT: 'consultant'
};

const RECRUITERS_ROLES = {
  OWNER: 'owner',
  ADMINISTRATOR: 'administrator',
  MEMBER: 'member',
  PENDING: 'pending'
};

const TOP_CANDIDATES = {
  ALL: 'all',
  TOP_5: '5',
  TOP_10: '10',
  TOP_15: '15',
  TOP_20: '20',
}

const EXIT_PREFIX_FOR_TITLE = {
  SA: 'SA',
  IC: 'IC'
}

export {
  EXAMPLE_QUESTION_CLASS_NAME,
  BAR_CENTER_CAMPAIGN_REPORT,
  MATCH_TWO_SIDE_SLIDER_BREAK_POINT,
  TALENT_TWO_SIDE_SLIDER_BREAK_POINT,
  BAR_CENTER_SELF_REPORT,
  HIGH_PREFORMER_MIN_SCORE,
  HIGH_POTENTIAL_MIN_SCORE,
  FETCH_SELF_TEST_REPORT_INTERVAL,
  CAMPAIGN_STATE,
  CAMPAIGN_TYPES,
  TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING,
  MAX_PRINT_CANDIDATES_ALLOWED,
  BEST_MATCH_SCORE_SUCCESS,
  SELF_TEST_SCORE_SUCESS,
  MIN_QUALIFIED_SCORE,
  RADIAL_PROGRESS_MULTIPLY,
  PERSONALITY_TYPES_COLORS,
  PERSONALITY_TYPES_COLORS_TITLES,
  REPORT_SCALE_MIN_VALUE,
  REPORT_SCALE_MAX_VALUE,
  REPORT_SCALE_MIN_SCORE,
  REPORT_SCALE_MAX_SCORE,
  REPORT_SCALE_THRESHOLD,
  REPORT_SCALE_SCALED_RANGE,
  REPORT_SCALE_RANGE,
  USER_TYPES,
  RECRUITER_TYPE,
  JOB_SEEKER_TYPE,
  NOT_REGISTED_USER_TYPES,
  CONSOLE_LOG_MSG,
  PROMO_CODE_PREFIXES,
  ATTRIBUTES_KEYS_MATCH,
  ATTRIBUTES_KEYS_TALENT,
  CAMPAIGN_MIN_CANDIDATES,
  SELEFTEST_VALUES_MIN_VALUE,
  CAMPAIGN_MIN_TEAMS_CANDIDATES,
  JOB_COMPLEXITY,
  CURRENCIES,
  JOB_RANGE_TYPES,
  JOB_ARRANGEMENT_TYPES,
  JOB_ARRANGEMENT_PERIODS,
  AP_REPORT_SLELECTED_CANDIDATES_LS_KEY,
  AP_REPORT_MAX_CANDIDATES,
  AP_REPORT_BULK_HIRING_MAX_CANDIDATES,
  MATCH_SCORE_MIN,
  BEST_MATCH_STATUSES,
  JOB_LEVEL,
  PERFORMANCE_LEVEL,
  EXIT_CAMPAIGN_STATE,
  EXIT_CAMPAIGN_ROLE,
  EXAMIZ_APPS,
  EXIT_CAMPAIGN_TYPES,
  TEAM_MEMBERS_ROLE,
  EXIT_MAX_CHARACTERS_STRATEGY,
  EXIT_MAX_RISK,
  RECRUITERS_ROLES,
  TOP_CANDIDATES,
  EXIT_PREFIX_FOR_TITLE
}
